
export default {
    ['kfzAccordionList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/accordion-list/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/accordion-list/index').default);
            }, 'components/shop/accordion-list');
        });
    },
    ['kfzAccountHeader-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/account-header/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/account-header/index').default);
            }, 'components/shop/account-header');
        });
    },
    ['kfzBreadcrumbs-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/breadcrumbs/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/breadcrumbs/index').default);
            }, 'components/shop/breadcrumbs');
        });
    },
    ['kfzButton-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/buttons/button/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/buttons/button/index').default);
            }, 'components/shop/button');
        });
    },
    ['kfzHeaderButton-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/buttons/header-button/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/buttons/header-button/index').default);
            }, 'components/shop/header-button');
        });
    },
    ['kfzIconButton-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/buttons/icon-button/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/buttons/icon-button/index').default);
            }, 'components/shop/icon-button');
        });
    },
    ['kfzCarisItem-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/caris-item/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/caris-item/index').default);
            }, 'components/shop/caris-item');
        });
    },
    ['kfzCatalogList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/catalog-list/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/catalog-list/index').default);
            }, 'components/shop/catalog-list');
        });
    },
    ['kfzCheckbox-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/form/checkbox/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/form/checkbox/index').default);
            }, 'components/shop/checkbox');
        });
    },
    ['kfzDate-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/form/date/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/form/date/index').default);
            }, 'components/shop/date');
        });
    },
    ['kfzDropdown-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/form/dropdown/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/form/dropdown/index').default);
            }, 'components/shop/dropdown');
        });
    },
    ['kfzInput-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/form/input/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/form/input/index').default);
            }, 'components/shop/input');
        });
    },
    ['kfzPassword-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/form/password/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/form/password/index').default);
            }, 'components/shop/password');
        });
    },
    ['kfzPhone-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/form/phone/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/form/phone/index').default);
            }, 'components/shop/phone');
        });
    },
    ['kfzQuantitySelector-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/form/quantity-selector/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/form/quantity-selector/index').default);
            }, 'components/shop/quantity-selector');
        });
    },
    ['kfzRadio-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/form/radio/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/form/radio/index').default);
            }, 'components/shop/radio');
        });
    },
    ['kfzStepper-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/form/stepper/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/form/stepper/index').default);
            }, 'components/shop/stepper');
        });
    },
    ['kfzTextarea-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/form/textarea/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/form/textarea/index').default);
            }, 'components/shop/textarea');
        });
    },
    ['kfzImage-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/image/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/image/index').default);
            }, 'components/shop/image');
        });
    },
    ['kfzOverlay-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/overlay/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/overlay/index').default);
            }, 'components/shop/overlay');
        });
    },
    ['kfzProgressBar-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/progress-bar/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/progress-bar/index').default);
            }, 'components/shop/progress-bar');
        });
    },
    ['kfzTable-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/table/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/table/index').default);
            }, 'components/shop/table');
        });
    },
    ['kfzTabs-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/tabs/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/tabs/index').default);
            }, 'components/shop/tabs');
        });
    },
    ['kfzToast-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/toast/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/toast/index').default);
            }, 'components/shop/toast');
        });
    },
    ['kfzToastItem-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/01-atoms/toast-item/index'], (require) => {
                resolve(require('sets/shop/components/01-atoms/toast-item/index').default);
            }, 'components/shop/toast-item');
        });
    },
    ['kfzAutosuggestLayer-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/autosuggest-layer/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/autosuggest-layer/index').default);
            }, 'components/shop/autosuggest-layer');
        });
    },
    ['kfzAutosuggestLayerRelative-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/autosuggest-layer-relative/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/autosuggest-layer-relative/index').default);
            }, 'components/shop/autosuggest-layer-relative');
        });
    },
    ['kfzCarisBar-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/caris/caris-bar/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/caris/caris-bar/index').default);
            }, 'components/shop/caris-bar');
        });
    },
    ['kfzCarisCockpit-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/caris/caris-cockpit/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/caris/caris-cockpit/index').default);
            }, 'components/shop/caris-cockpit');
        });
    },
    ['kfzCarisDropdown-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/caris/caris-dropdown/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/caris/caris-dropdown/index').default);
            }, 'components/shop/caris-dropdown');
        });
    },
    ['kfzCarisFilter-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/caris/caris-filter/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/caris/caris-filter/index').default);
            }, 'components/shop/caris-filter');
        });
    },
    ['kfzCarisFilterTrigger-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/caris/caris-filter-trigger/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/caris/caris-filter-trigger/index').default);
            }, 'components/shop/caris-filter-trigger');
        });
    },
    ['kfzCarisGarage-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/caris/caris-garage/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/caris/caris-garage/index').default);
            }, 'components/shop/caris-garage');
        });
    },
    ['kfzCarisList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/caris/caris-list/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/caris/caris-list/index').default);
            }, 'components/shop/caris-list');
        });
    },
    ['kfzCarpageDropdown-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/carpage-dropdown/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/carpage-dropdown/index').default);
            }, 'components/shop/carpage-dropdown');
        });
    },
    ['kfzCategoriesHeader-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/categories-header/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/categories-header/index').default);
            }, 'components/shop/categories-header');
        });
    },
    ['kfzCategoriesList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/categories-list/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/categories-list/index').default);
            }, 'components/shop/categories-list');
        });
    },
    ['kfzCmsBanners-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/cms/cms-banners/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/cms/cms-banners/index').default);
            }, 'components/shop/cms-banners');
        });
    },
    ['kfzCmsCarousel-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/cms/cms-carousel/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/cms/cms-carousel/index').default);
            }, 'components/shop/cms-carousel');
        });
    },
    ['kfzCmsPrimarySecondaryBanners-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/cms/cms-primary-secondary-banners/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/cms/cms-primary-secondary-banners/index').default);
            }, 'components/shop/cms-primary-secondary-banners');
        });
    },
    ['kfzCrossSelling-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/cross-selling/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/cross-selling/index').default);
            }, 'components/shop/cross-selling');
        });
    },
    ['kfzDevError-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/dev-error/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/dev-error/index').default);
            }, 'components/shop/dev-error');
        });
    },
    ['kfzPaymentDialog-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/dialogs/payment-dialog/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/dialogs/payment-dialog/index').default);
            }, 'components/shop/payment-dialog');
        });
    },
    ['kfzTheDialog-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/dialogs/the-dialog/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/dialogs/the-dialog/index').default);
            }, 'components/shop/the-dialog');
        });
    },
    ['kfzExpandableContainer-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/expandable-container/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/expandable-container/index').default);
            }, 'components/shop/expandable-container');
        });
    },
    ['kfzFilterSelect-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/filter-select/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/filter-select/index').default);
            }, 'components/shop/filter-select');
        });
    },
    ['kfzFoldableLinksList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/foldable-links-list/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/foldable-links-list/index').default);
            }, 'components/shop/foldable-links-list');
        });
    },
    ['kfzFooterNavigation-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/footer/footer-navigation/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/footer/footer-navigation/index').default);
            }, 'components/shop/footer-navigation');
        });
    },
    ['kfzFooterServices-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/footer/footer-services/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/footer/footer-services/index').default);
            }, 'components/shop/footer-services');
        });
    },
    ['kfzAccordion-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/form/accordion/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/form/accordion/index').default);
            }, 'components/shop/accordion');
        });
    },
    ['kfzAddressFormFields-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/form/address-form-fields/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/form/address-form-fields/index').default);
            }, 'components/shop/address-form-fields');
        });
    },
    ['kfzClipboardForm-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/form/clipboard-form/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/form/clipboard-form/index').default);
            }, 'components/shop/clipboard-form');
        });
    },
    ['kfzComputopForm-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/form/computop-form/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/form/computop-form/index').default);
            }, 'components/shop/computop-form');
        });
    },
    ['kfzFormFields-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/form/form-fields/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/form/form-fields/index').default);
            }, 'components/shop/form-fields');
        });
    },
    ['kfzFormWrapper-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/form/form-wrapper/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/form/form-wrapper/index').default);
            }, 'components/shop/form-wrapper');
        });
    },
    ['kfzInlineFormField-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/form/inline-form-field/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/form/inline-form-field/index').default);
            }, 'components/shop/inline-form-field');
        });
    },
    ['kfzPaymentForm-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/form/payment-form/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/form/payment-form/index').default);
            }, 'components/shop/payment-form');
        });
    },
    ['kfzPaymentMethodForm-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/form/payment-method-form/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/form/payment-method-form/index').default);
            }, 'components/shop/payment-method-form');
        });
    },
    ['kfzHeaderAccountButton-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/header/header-account-button/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/header/header-account-button/index').default);
            }, 'components/shop/header-account-button');
        });
    },
    ['kfzMiniCart-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/header/mini-cart/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/header/mini-cart/index').default);
            }, 'components/shop/mini-cart');
        });
    },
    ['kfzOptionBar-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/header/option-bar/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/header/option-bar/index').default);
            }, 'components/shop/option-bar');
        });
    },
    ['kfzLogoutButton-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/logout-button/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/logout-button/index').default);
            }, 'components/shop/logout-button');
        });
    },
    ['kfzNavigationDrawer-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/navigation-drawer/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/navigation-drawer/index').default);
            }, 'components/shop/navigation-drawer');
        });
    },
    ['kfzNewsletterForm-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/newsletter-form/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/newsletter-form/index').default);
            }, 'components/shop/newsletter-form');
        });
    },
    ['kfzPaymentIframeContainer-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/payment-iframe-container/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/payment-iframe-container/index').default);
            }, 'components/shop/payment-iframe-container');
        });
    },
    ['kfzPdpCarBar-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/pdp-car-bar/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/pdp-car-bar/index').default);
            }, 'components/shop/pdp-car-bar');
        });
    },
    ['kfzProductDetailImage-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/product-detail-image/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/product-detail-image/index').default);
            }, 'components/shop/product-detail-image');
        });
    },
    ['kfzSeriesModelList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/series-model-list/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/series-model-list/index').default);
            }, 'components/shop/series-model-list');
        });
    },
    ['kfzSliderContainer-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/slider-container/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/slider-container/index').default);
            }, 'components/shop/slider-container');
        });
    },
    ['kfzActionsTile-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/tiles/actions-tile/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/tiles/actions-tile/index').default);
            }, 'components/shop/actions-tile');
        });
    },
    ['kfzAddressTile-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/tiles/address-tile/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/tiles/address-tile/index').default);
            }, 'components/shop/address-tile');
        });
    },
    ['kfzPaymentTile-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/tiles/payment-tile/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/tiles/payment-tile/index').default);
            }, 'components/shop/payment-tile');
        });
    },
    ['kfzProductTile-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/tiles/product-tile/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/tiles/product-tile/index').default);
            }, 'components/shop/product-tile');
        });
    },
    ['kfzTileActions-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/tiles/tile-actions/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/tiles/tile-actions/index').default);
            }, 'components/shop/tile-actions');
        });
    },
    ['kfzTileExtras-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/tiles/tile-extras/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/tiles/tile-extras/index').default);
            }, 'components/shop/tile-extras');
        });
    },
    ['kfzVehicleTile-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/tiles/vehicle-tile/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/tiles/vehicle-tile/index').default);
            }, 'components/shop/vehicle-tile');
        });
    },
    ['kfzVoucherBox-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/voucher-box/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/voucher-box/index').default);
            }, 'components/shop/voucher-box');
        });
    },
    ['kfzVoucherTrigger-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/02-molecules/voucher-trigger/index'], (require) => {
                resolve(require('sets/shop/components/02-molecules/voucher-trigger/index').default);
            }, 'components/shop/voucher-trigger');
        });
    },
    ['kfzCarpageCarSelection-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/carpage-car-selection/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/carpage-car-selection/index').default);
            }, 'components/shop/carpage-car-selection');
        });
    },
    ['kfzAddressForm-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/forms/address-form/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/forms/address-form/index').default);
            }, 'components/shop/address-form');
        });
    },
    ['kfzUserForm-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/forms/user-form/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/forms/user-form/index').default);
            }, 'components/shop/user-form');
        });
    },
    ['kfzHeader-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/header/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/header/index').default);
            }, 'components/shop/header');
        });
    },
    ['kfzAddressList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/lists/address-list/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/lists/address-list/index').default);
            }, 'components/shop/address-list');
        });
    },
    ['kfzContentList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/lists/content-list/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/lists/content-list/index').default);
            }, 'components/shop/content-list');
        });
    },
    ['kfzOrderList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/lists/order-list/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/lists/order-list/index').default);
            }, 'components/shop/order-list');
        });
    },
    ['kfzPaymentList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/lists/payment-list/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/lists/payment-list/index').default);
            }, 'components/shop/payment-list');
        });
    },
    ['kfzProductList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/lists/product-list/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/lists/product-list/index').default);
            }, 'components/shop/product-list');
        });
    },
    ['kfzVehicleList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/lists/vehicle-list/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/lists/vehicle-list/index').default);
            }, 'components/shop/vehicle-list');
        });
    },
    ['kfzLoginContainer-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/login-container/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/login-container/index').default);
            }, 'components/shop/login-container');
        });
    },
    ['kfzOrderConfirmation-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/order-confirmation/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/order-confirmation/index').default);
            }, 'components/shop/order-confirmation');
        });
    },
    ['kfzPollingLoader-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/polling-loader/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/polling-loader/index').default);
            }, 'components/shop/polling-loader');
        });
    },
    ['kfzProductDetails-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/03-organisms/product-details/index'], (require) => {
                resolve(require('sets/shop/components/03-organisms/product-details/index').default);
            }, 'components/shop/product-details');
        });
    },
    ['kfzCatalogBrandFilter-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/catalog/catalog-brand-filter/index'], (require) => {
                resolve(require('sets/shop/components/catalog/catalog-brand-filter/index').default);
            }, 'components/shop/catalog-brand-filter');
        });
    },
    ['kfzCatalogCheckboxFilter-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/catalog/catalog-checkbox-filter/index'], (require) => {
                resolve(require('sets/shop/components/catalog/catalog-checkbox-filter/index').default);
            }, 'components/shop/catalog-checkbox-filter');
        });
    },
    ['kfzCatalogDropdownFilter-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/catalog/catalog-dropdown-filter/index'], (require) => {
                resolve(require('sets/shop/components/catalog/catalog-dropdown-filter/index').default);
            }, 'components/shop/catalog-dropdown-filter');
        });
    },
    ['kfzCatalogFilterRow-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/catalog/catalog-filter-row/index'], (require) => {
                resolve(require('sets/shop/components/catalog/catalog-filter-row/index').default);
            }, 'components/shop/catalog-filter-row');
        });
    },
    ['kfzCatalogFilterSection-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/catalog/catalog-filter-section/index'], (require) => {
                resolve(require('sets/shop/components/catalog/catalog-filter-section/index').default);
            }, 'components/shop/catalog-filter-section');
        });
    },
    ['kfzCatalogRangeFilter-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/catalog/catalog-range-filter/index'], (require) => {
                resolve(require('sets/shop/components/catalog/catalog-range-filter/index').default);
            }, 'components/shop/catalog-range-filter');
        });
    },
    ['kfzCatalogSorting-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/catalog/catalog-sorting/index'], (require) => {
                resolve(require('sets/shop/components/catalog/catalog-sorting/index').default);
            }, 'components/shop/catalog-sorting');
        });
    },
    ['kfzProductCard-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/catalog/product-card/index'], (require) => {
                resolve(require('sets/shop/components/catalog/product-card/index').default);
            }, 'components/shop/product-card');
        });
    },
    ['kfzProductCardList-388']: () => {
        return new Promise((resolve) => {
            require.ensure(['sets/shop/components/catalog/product-card-list/index'], (require) => {
                resolve(require('sets/shop/components/catalog/product-card-list/index').default);
            }, 'components/shop/product-card-list');
        });
    }
};
